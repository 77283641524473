//RELATIVE
.pos-r {
	position: relative;
}

//ABOSLUTE
.pos-a-tl {
	position: absolute;
	top: 0;
	left: 0;
}

.pos-a-tr {
	position: absolute;
	top: 0;
	right: 0;
}