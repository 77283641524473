//Margin Generator by nick fetner
@mixin spacing-helpers( $multiplier ) {
	@for $i from 0 through 15 {
		$adjust: ( $multiplier * $i );
	  	
	   .m#{$i} { margin: #{$adjust}px; }
	   .mt#{$i} { margin-top: #{$adjust}px; }
	   .mb#{$i} { margin-bottom: #{$adjust}px; }
	   .ml#{$i} { margin-left: #{$adjust}px; }
	   .mr#{$i} { margin-right: #{$adjust}px; }
	}
}

@include spacing-helpers( 6 );

@media(min-width: $screen-md-min) {
	@include spacing-helpers( 8 );	
}

@media(min-width: $screen-lg-min) {
	@include spacing-helpers( 10 );		
}