
//TEXT
.red {
	color: $red;
}
.tan-darker {
	color: $tan-darker;
}

//TEXT
.white-bg {
	background-color: white;
}

.light-tan-bg {
	background-color: #F9F6F2;
}


//HOVERS
.hover-tan-dark {
	&:hover {
		color: $tan-darker;
		transition: all 0.2s;
	}
	transition: all 0.2s;
}