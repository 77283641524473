.footer-kayla-world {
	.hip {
		display: none;
		@media (min-width: $screen-md-min) {
			h6 {
				position: relative;
				z-index: 10;
				margin-right: 100px;
			}
			img {
				margin-top: -20px;
				margin-left: -130px;
			}
			display: block;
			position: absolute;
			top: 100px;
			left: 26%;
		}
	}
	.moon {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 100px;
			right: 20%;
			width: 100px;
		}
	}	
	.cloud {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 300px;
			right: 40%;
		}
	}
	.envelope {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 400px;
			left: 0;
		}	
	}
	.details {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 630px;
			right: 15%;
		}		
	}
	.a {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 500px;
			right: 0px;
		}			
	}
	.simple {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 800px;
			right: 30%;
		}				
	}
	.together {
		.bb-2 {
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
		}
		img {
			float: right;
			margin-top: -30px;
		}
		width: 300px;
		margin-right: auto;
		margin-left: auto;
		display: none;
		text-align: center;
		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			top: 850px;
			left: 25%;
			margin-bottom: 100px;
		}					
	}
	position: relative;
}