.navbar {
	.nav, .navbar-form  {
		padding: 15px 0;
	}
	.tan {
		a {
			color: #DED4CB !important;
		}
	}
	.navbar-nav {
		li {
			padding-right: 40px;
		}
		.current-menu-item a {
			border-bottom: none;
			@media(min-width: $screen-md-min) {
				border-bottom: 3px solid red;
			}
		}
		a {
			@media(min-width: $screen-md-min) {
				&.home-logo:hover {
					border-bottom: none;
				}
				&:hover {
					border-bottom: 3px solid red;
				}	
			}
			letter-spacing: 1px;
			padding-bottom: 4px;
			padding-left: 0;
			padding-right: 0;
		}
	}
	z-index: 3;
	@media (min-width: $screen-md-min) {
		overflow: hidden;	
		max-height: 90px;
	}
}
.navbar-collapse {
	.navbar-nav li {
		@media(max-width: $screen-xs-min) {
			padding-right: 0;
		}
		@media(max-width: $screen-md-min) {
			padding-right: 20px;
		}
	}
	li a {
		border-bottom: 3px solid transparent;
	}
	text-align: center;
}
.navbar-mobile {
	display: inline-block;
	width: 100%;
	@media(min-width: $screen-md-min) {
		display: none;
	}
}