.work-item-table {
	display: table;
	height: 100%;
	width: 100%;
}


//IMAGE
.work-item-image-table {
	display: block;
	width: 100%;
	@media (min-width: $screen-sm-min) {
		display: table-cell;
		width: 60%;
	}
}
.work-item-image-left {
	@media (min-width: $screen-sm-min) {
		padding-left: 120px;
		padding-right: 30px;
	}
}
.work-item-image-right {
	@media (min-width: $screen-sm-min) {
		padding-right: 50px;
		padding-left: 30px;
	}
}
.work-item-image-overlap {
	position: relative;
}
.work-item-image-overlap-left {
	display: none;
	@media (min-width: $screen-lg-min) {
		display: inline-block;
		position: absolute;
		bottom: 10%;
		left: -30%;	
	}
}	


//CONTENT
.work-item-content {
	display: block;
	vertical-align: bottom;
	width: 100%;
	padding-left: 2%;
	@media (min-width: $screen-sm-min) {
		display: table-cell;
	}
} 
.work-item-divider {
	width: 87%;
	margin-top: 17px;
	border-top: 2px solid $gray-base;
}
.work-item-header {
	padding-left: 15px;
	padding-bottom: 15px;
	display: inline-block;
	vertical-align: top;
	width: 100%;
	position: relative;
	z-index: 10;
}


//SECTION SPACING
.work-item-one-third {
	display: inline-block;
	width: 25%;
	vertical-align: top;
}

.work-item-two-thirds {
	display: inline-block;
	width: 65%;
	position: relative;
}

//VERTICAL
.work-item-bottom {
	vertical-align: bottom;
	padding-bottom: 130px;
}

.work-together-cta {
	letter-spacing: 0.1em;
	font-size: 17px;
}


//TYPE
.work-item-number {
	letter-spacing: 2px;
}