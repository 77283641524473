img { 
	max-width: 100%;
	display: block;
}

.row.no-pad {
  margin-right:0;
  margin-left:0;
}
.row.no-pad > [class*='col-'] {
  padding-right:0;
  padding-left:0;
}

.mw-1100 {
	max-width: 1090px;
	margin-left: auto;
	margin-right: auto;
}