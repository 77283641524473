.align-vertical-anything {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.align-vertical-middle {
	display: inline-block;
	vertical-align: middle;
}

.align-horizontal-middle {
	margin-left: auto;
	margin-right: auto;
}


//TEXT

.text-center-md-max {
	@media(max-width: $screen-md-min) {
		text-align: center;
	}
}