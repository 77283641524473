//WEIGHTS
///EB Garamond
///PT Sans:400,400italic,700,700italic


.serif {
	font-family: $font-family-serif;
}

.sans-serif {
	font-family: $font-family-sans-serif;
}

//HEADINGS
h1,h2,h3,h4,h5,h6,.h7,.h8,.h9,.h10 {
	color: $gray-base;
}
h1 {
	@media (max-width: $screen-xs-min) {
		font-size: $font-size-h1 * 0.8;
	}
}
h2 {
	letter-spacing: 1px;
	@media (max-width: $screen-xs-min) {
		font-size: $font-size-h2 * 0.9;
	}
}
h3 {
	letter-spacing: 2px;
}
h4 {

}
h5 {
	letter-spacing: 2px;
}
h6 {

}
.h7 {
	font-size: $font-size-h7;
}
.h8 {
	font-size: $font-size-h8;
}
.h9 {
	font-size: $font-size-h9;
}
.h10 {
	font-size: $font-size-h10;
	font-family: $font-family-sans-serif;
	font-weight: 400;
}




//NAV
.navbar-nav li a {
	font-size: $font-size-large;
}





//PARAGRAPHS
p {
	font-size: 19px;
	font-weight: normal;
	letter-spacing: 0.05em;
}

.par-2 {
	font-size: 16px;
}

.par-3 {
	font-size: 14px;
	font-family: $font-family-sans-serif;
}

.par-4 {
	font-size: 12px;
	font-family: $font-family-sans-serif;
}