//PARENT POSITION
.brush-below-parent {
	position: relative;
	z-index: 10;
}


//BRUSH POSITION
.brush-below-child {
	position: absolute;
	bottom: -5px;
	left: -100px;
	width: 300px;
}

.brush-below-child-center {
	position: absolute;
	top: 30px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 230px;
}

//I THINK THIS IS OLD BUT INCLUDED IT FROM THE HELPERS FILE
.bg-brush-stroke {
	background-image: url('../img/brush-stroke.png');
}