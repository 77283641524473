.footer-container {
	display: inline-block;
	position: relative;
	border-top: 1px solid $gray-light;
	margin-bottom: 40px;
	@media(min-width: $screen-md-min) {
		margin-bottom: 0;
		padding-top: 20px;
	}
}
.footer-content-container {
	@media(max-width: $screen-md-min) {
		text-align: center;
	}
}
.footer-content-container-column {
	display: inline-block;
	width: 100%;
	@media(min-width: $screen-md-min) {
		width: 34%;
		&:nth-child(2) {
			margin-left: 35px;
		}
		&:nth-child(3) {
			padding-left: 30px;
		}
	}
}