.header-landing {
	min-height: 30vh;
	background-size: 100%;
	background-position: 20px -5px;
	background-repeat: no-repeat;
	background-position: fixed;
	text-align: center;
	position: relative;
	@media(min-width: $screen-xs-min) {
		min-height: 40vh;
		background-size: 100%;
		background-position: 20px -5px;
	}
	@media(min-width: $screen-sm-min) {
		min-height: 55vh;
		background-size: 100%;
		background-position: 20px -5px;
	}
	@media(min-width: $screen-md-min) {
		min-height: 65vh;
		background-size: 100%;
		background-position: 20px -5px;
	}
	@media(min-width: $screen-lg-min) {
		min-height: 95vh;
		background-size: 100%;
		background-position: 100px -50px;
	}
}

.down-arrow {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	cursor: pointer;
}