$slick-font-path: "./fonts/" !default;
$slick-font-family: "FontAwesome";
$slick-loader-path: "./" !default;
$slick-arrow-color: $gray-light;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104";
$slick-next-character: "\f105";
// $slick-dot-character: "•" !default;
// $slick-dot-size: 6px !default;
// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.25 !default;
//
//

.slider {
	a:focus, a:active {
		border: none;
		box-shadow: none;
		outline: none;
	}
}
.container .slick-slider {
	margin-bottom: 15px;
}
.slick-track {
	margin-left: auto;
	margin-right: auto;
}