//Padding Generator by nick fetner
@mixin spacing-helpers( $multiplier ) {
	@for $i from 0 through 10 {
		$adjust: ( $multiplier * $i );
	  	
	   .p#{$i} { padding: #{$adjust}px; }
	   .pt#{$i} { padding-top: #{$adjust}px; }
	   .pb#{$i} { padding-bottom: #{$adjust}px; }
	   .pl#{$i} { padding-left: #{$adjust}px; }
	   .pr#{$i} { padding-right: #{$adjust}px; }
	}
}

@include spacing-helpers( 6 );

@media(min-width: $screen-md-min) {
	@include spacing-helpers( 8 );	
}

@media(min-width: $screen-lg-min) {
	@include spacing-helpers( 10 );		
}


//BOOTSTRAP PADDING
.pl-15 {
	padding-left: 15px;
}
.pr-15 {
	padding-right: 15px;
}