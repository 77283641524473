.form-contact {
	.form-contact-check-container {
		display: none;
		padding-bottom: 15px;
		@media (min-width: $screen-sm-min) { 
			display: block;
		}
	}
	.wpcf7-list-item-label {
		font-size: 14px;
		font-family: $font-family-sans-serif;
		letter-spacing: 1px;
		padding-left: 6px;
		cursor: pointer;
		display: inline-block;
		max-width: 90%;
	}
	span.wpcf7-list-item {
		position: relative;
		margin-left: 0px;
		display: block;
	}
	input[type="checkbox"] {
		cursor: pointer;
		border: 1px solid $gray-base;
		border-radius: 0;
		display: block;
		float: left;
		margin-top: 8px;
	}
	input[type="submit"] {
		&:hover {
			background-color: $gray-light;
			transition: all 0.2s;
		}
		transition: all 0.2s;
		background-color: $gray-base;
		color: white;
		padding: 10px 25px;
		letter-spacing: 1px;
		font-family: $font-family-serif;
	}
	input, textarea {
		&:focus, &:active {
			box-shadow: none;
		}
		background-color: white;
		border: none;
		box-shadow: none;
		padding: 18px;
		font-family: $font-family-sans-serif;
		color: black;
		letter-spacing: 1px;
	}
	input::-webkit-input-placeholder {
   		color: black;
   		font-size: 14px;
   		letter-spacing: 2px;
	}

	input:-moz-placeholder { /* Firefox 18- */
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

	input::-moz-placeholder {  /* Firefox 19+ */
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

	input:-ms-input-placeholder {  
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

	textarea::-webkit-input-placeholder {
   		color: black;
   		font-size: 14px;
   		letter-spacing: 2px;
	}

	textarea:-moz-placeholder { /* Firefox 18- */
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

	textarea::-moz-placeholder {  /* Firefox 19+ */
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

	textarea:-ms-input-placeholder {  
	   color: black;
	   font-size: 14px;
	   letter-spacing: 2px;  
	}

}

.form-control {
	box-shadow: none;
	font-family: $font-family-sans-serif;
	font-size: 14px;
	font-family: $font-family-sans-serif;
	letter-spacing: 1px;
	padding-left: 6px;
	cursor: pointer;
	display: inline-block;
	color: black;
}
