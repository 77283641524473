.list-quick-container {
	@media(min-width: $screen-md-min) {
		text-align: center;
	}
}
.list-heading-quick {
	.h9 {
		padding-left: 5px;
	}
	display: block;
	text-align: center;
	margin-bottom: 7px;
	@media(min-width: $screen-md-min) {
		display: inline-block;
		text-align: left;
	}
}
.list-heading-quick-number {
	display: inline-block;
	margin-top: 5px;
	vertical-align: top;
	font-size: 11px;
	font-family: $font-family-sans-serif;
}
.list-quick {
	font-family: $font-family-sans-serif;
	font-weight: bold;
	list-style: none;
	font-size: 11px;
	letter-spacing: 1px;
	line-height: 22px;
	padding-left: 0;
	@media(min-width: $screen-md-min) {
		padding-left: 32px;
	}
}

.list-number-large {
	display: inline-block;
	margin-top: 25px;
	vertical-align: top;
	font-size: 18px;
	letter-spacing: 1px;
	font-family: $font-family-sans-serif;
}