

//KAYLA ADAMS CUSTOM
.creative-studio-icon img {
	display: inline-block;
	max-height: 55px;
}


.down-arrow {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	cursor: pointer;
}

.icon-x {
	position: absolute;
	top: -20px;
	right: 50px;
	cursor: pointer;
	height: 50px;
	z-index: 2;
}