//REVERSE


//MAX MD
.order-reverse-md, .order-item-1, .order-item-2 {
	@media(max-width: $screen-md-min) {
		-webkit-transform: rotate(180deg);
	       -moz-transform: rotate(180deg);
	        -ms-transform: rotate(180deg);
	         -o-transform: rotate(180deg);
	            transform: rotate(180deg);
	}	
}
