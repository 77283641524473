.portfolio-item-image {
	cursor: pointer;
}

.portfolio-item-container {
	.number { 
		font-family: "PT Sans Narrow",Helvetica,Arial,sans-serif;
	}
}

.portfolio-slider {
	&.slick-initialized {
		opacity: 1;	
		transition: opacity .3s;
	}
	.slick-slide {
		outline: none !important;
	}
	opacity: 0;
	transition: opacity 0s;
	img {
		max-width: 1140px;
		height: auto;
		max-height: 200px;
		margin-right: 6px;
		@media (min-width: $screen-sm-min) { 
			max-height: 500px;
		}
	}
}

.portfolio-item-card {
	&.active .overlay { opacity: 1;}
	.overlay {
		&:hover {
			opacity: 1;
		}
		.link {
			font-family: "PT Sans Narrow",Helvetica,Arial,sans-serif;
			text-transform: uppercase;
			position: absolute;
			bottom: 15px;
			right: 20px;

		}
		h5 {
			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
				font-size: 16px;
			}
			margin-top: 15px;
		}
		background-color: white;
		position: absolute;
		top: 25px; bottom: 25px;
		left: 25px; right: 25px;
		z-index: 1;
		opacity: 0;
		padding: 5px 10px;
		transition: opacity .3s;
	}
	cursor: pointer;
	position: relative;
	padding: 5px !important;
	clear: both;
	@media (min-width: $screen-sm-min) {
		clear: none;
	}
}

.portfolio-expander {
	display: none;
	p {
		font-size: 15px;
	}
	
	.external-link {
		font-family: "PT Sans Narrow",Helvetica,Arial,sans-serif;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.05em;
	}

	.controls {
		.chevron {
			position: relative;
			top: -1px;
		}
		text-align: right;
		font-family: "PT Sans Narrow",Helvetica,Arial,sans-serif;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.05em;
	}
	.prev, .next {
		&:hover {
			color: $red;
		}
		padding: 0 5px;
		cursor: pointer;
	}
	.inner {
		padding: 20px;
		padding-bottom: 10px;
	}
	.icon-x { top: -6px; }

	.slick-next {
 	   right: -45px;
 	   height: 50px;
	}
	.slick-prev {
 	   left: -45px;
 	   height: 50px;
	}
	.slick-prev:before, .slick-next:before {
		font-size: 50px;
		color: $pink;
	}
	background-color: white;
	margin: 0 7px;
    margin-top: 11px;
    margin-bottom: 11px;
    border-bottom: 1px solid $gray-light;
    padding-bottom: 5px;
}
