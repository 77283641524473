.inline {
	display: inline;
}

.table {
	display: table;
	width: 100%;
}
.table-cell	{
	display: table-cell;
	vertical-align: middle;
}

.table-md {
	@media(min-width: $screen-md-min) {
		display: table;
		width: 100%;
	}
}
.table-cell-md	{
	@media(min-width: $screen-md-min) {
		display: table-cell;
		vertical-align: middle;
	}
}