.link-hover {
	&:hover {
		border-bottom: 3px solid $red;
	}
	font-family: $font-family-sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	padding-bottom: 3px;
	border-bottom: 3px solid transparent;
	transition: all 0.2s;	
}

.chevron {
	font-size: 12px;
	font-weight: bold;
	margin-left: 4px;
	letter-spacing: -0.5px;
}


//NAV
.navbar .active a {
	border-bottom: 3px solid $red;
}