//ALL
.image-border {
	border: 10px solid white;
}

.image-border-bottom {
	border: 10px solid white;
}
.image-bb-double {
	width: 100%;
	height: 6px;
	background-color: $tan-dark;
}


//BORDER LEFT
.bl-white-thick-md {
	@media(min-width: $screen-md-min) {
		border-left: 10px solid white;
		margin-right: -25px;	
	}
}

.bl-gray-line-md {
	@media(min-width: $screen-md-min) {
		border-left: 1px solid $gray-light;
	}
}


//BORDER RIGHT
.br-white-thick-md {
	@media(min-width: $screen-md-min) {
		border-right: 10px solid white;
		margin-left: -25px;	
	}
}
.br-gray-line-md {
	@media(min-width: $screen-md-min) {
		border-right: 1px solid $gray-light;
		height: 100%;
	}
}


//BORDER BOTTOM
///SINGLE
.bb-1 {
	border-bottom: 1px solid $gray-base;
}
.bb-1-gray-light {
	border-bottom: 1px solid $gray-light;
}
.bb-2 {
	border-bottom: 2px solid $gray-base;
}
.bb-tan-thick {
	position: relative;
	border-bottom: 5px solid $tan-dark;
}
///DOUBLE
.bt-gray-line-gray-line {
	position: relative;
	border-top: 1px solid $gray-light;
}
.bt-gray-line-gray-line:after {
	content: " ";
	position: absolute;
	z-index: 100;
	width: 100%;
	left: 0;
	right: 0;
	top: 2px;
	border-top: 1px solid $gray-light;
	z-index: 1;
}

.bb-gray-line-tan-thick {
	position: relative;
	border-bottom: 5px solid $tan-dark;
}
.bb-gray-line-tan-thick:before {
	content: " ";
	position: absolute;
	z-index: 100;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0px;
	border-bottom: 1px solid $gray-light;
	z-index: 1;
}

.bb-tan-thick-gray-line {
	position: relative;
	border-bottom: 7px solid $tan-dark;
}

.bb-tan-thick-gray-line:after {
	content: " ";
	position: absolute;
	z-index: 100;
	width: 100%;
	left: 0;
	right: 0;
	bottom: -7px;
	border-bottom: 1px solid $gray-light;
	z-index: 1;
}

.bb-gray-line {
	border-bottom: 1px solid $gray-lighter;
}

.bb-gray-line-md-max {
	@media(max-width: $screen-md-min) {
		border-bottom: 1px solid $gray-lighter;
	}
}

//DECORATIONS
.b-box-top {
	position: absolute;
	top: -4px;
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto;
	height: 8px;
	width: 32px;
	text-align: center;
	background-color: $gray-light;
}