.expand-custom {
}

//ME SECTION
.expand-custom-me {

}
.expand-custom-me-table-width {
	position: relative;
	width: 100%;
	margin-top: 30px;
	padding-top: 30px;
	padding-bottom: 50px;
	border-bottom: 1px solid $gray-light;
	@media(min-width: $screen-md-min) {
		width: 35%;
		padding-bottom: 0;
		border-bottom: none;
	}
}

//TEXT SECTION
.expand-custom-text-number {
	width: 20%;
	display: inline-block;
	vertical-align: top;
	margin-top: 30px;
	@media(min-width: $screen-md-min) {
		width: 8%;
	}
}
.expand-custom-text-title {
	width: 60%;
	display: inline-block;
	@media(min-width: $screen-md-min) {
		width: 92%;
	}
}

.expand-custom-text-description {
	width: 90%;
	@media(max-width: $screen-md-min) {
		padding-left: 8%;
	}
	@media(min-width: $screen-md-min) {
		width: 92%;
	}
}

//CLIENT SECTION
.expand-custom-clients {
	img {
		max-height: 250px;
	}
}